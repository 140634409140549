<template>
  <div class="relative min-h-screen">
    <h4 class="text-center mt-3 mb-3">Stock</h4>
    <div class="rounded-lg mb">
      <div class="flex flex-col md:flex-row md:justify-center">
        <div class="bg-white md:min-w-1/2 rounded-lg">
          <div class="p-6 text-center">
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Item Name:</span>
              {{ stock.itemName }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Unit Selling Price(₦):</span>
              {{ stock.unitSellingPrice.toLocaleString() }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Unit:</span>
              {{ stock.unit }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Quantity Remaining:</span>
              {{ stock.quantityRemaining }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Created By:</span>
              {{ stock.authorId.name }}
            </p>
            <p class="md:text-xs text-darkblue mb-2">
              <span class="font-bold"> Reg. Date:</span>
              {{ regDate }}
            </p>

            <div class="mt-2">
              <button
                @click="EditStock"
                type="button"
                class="bg-green-600 hover:bg-darkblue mr-2 md:text-xs text-white rounded-lg pt-1 pb-1 pl-4 pr-4"
              >
                Edit
              </button>
              <button
                @click="showDeleteModal = true"
                type="button"
                class="bg-red-600 md:text-xs hover:bg-darkblue text-white rounded-lg pt-1 pb-1 pl-4 pr-4"
              >
                Delete
              </button>
            </div>
          </div>
          <div v-if="loadingSpinner">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <div>
          <transition name="slide-fade">
            <NotificationModal
              v-if="showDeleteModal"
              :modalData="modalData"
              @modal-success="DeleteStock($event)"
              @modal-close="CloseModal($event)"
            />
          </transition>
        </div>
      </div>
    </div>
    <form
      @submit.prevent="GetStockBreakdown"
      class="flex mt-5 pt-5 md:flex-row flex-col"
    >
      <div class="w-full md:w-1/2 md:max-w-1/2 md:mr-4 mb-4 md:mb-0">
        <p class="text-darkblue mb-2 md:text-xs text-sm md:block hidden">
          Select date range below to show stock breakdown
        </p>
        <t-datepicker
          :classes="datePickerDefaultClass"
          :dateFormat="'Y-m-d H:i:S'"
          :userFormat="'Y-m-d'"
          :placeholder="'Select date range'"
          :range="true"
          :required="true"
          v-model="selectedDate"
        />
      </div>
      <div class="md:mr-4">
        <p class="text-darkblue md:text-xs text-sm mb-2 md:block hidden">
          Select Transaction type
        </p>
        <div class="flex flex-row justify-center md:justify-start">
          <div>
            <t-select
              fixedClasses="inline md:text-xs text-sm h-11 md:h-9 rounded-l-lg cursor-pointer"
              placeholder="Filter"
              v-model="breakdownSearchLocation"
              :options="breakdownSearchLocationOptions"
              :required="true"
            ></t-select>
          </div>
          <div>
            <button
              class="bg-darkblue text-sm rounded-r-lg md:text-xs pt-1 pb-1 mb-3 text-white hover:bg-blue-600 focus:text-white focus:bg-black pl-2 pr-2 h-11 md:h-9"
              type="submit"
            >
              Get Stock Breakdown
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="">
      <form>
        <span class="md:text-xs mr-2">Show</span>
        <select
          class="md:text-xs h-11 md:h-8 border-white rounded-lg"
          v-model="breakdownLimit"
          name="limit"
          id=""
        >
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="40">40</option>
          <option value="60">60</option>
          <option value="80">80</option>
          <option value="100">100</option>
          <option value="200">200</option>
        </select>
        <span class="md:text-xs ml-2">entries</span>
      </form>
    </div>
    <div class="mt-5 overflow-auto">
      <t-table
        id="my-table"
        :classes="tableClasses"
        :headers="tableHeader"
        :data="tableData"
      >
        <template slot="thead" slot-scope="props">
          <thead :class="props.theadClass">
            <tr :class="props.trClass">
              <th
                v-for="(item, index) in props.data"
                :class="props.thClass"
                :key="index"
              >
                {{ item.text }}
              </th>
            </tr>
          </thead>
        </template>

        <template slot="column" slot-scope="props">
          <td @click="ClickRow(props)" :class="props.tdClass">
            {{ props.text }}
          </td>
        </template>
      </t-table>

      <p
        class="mt-3 mb-3 text-center md:text-sm text-darkblue"
        v-if="tableData.length < 1"
      >
        No data to show
      </p>
    </div>
    <div class="mt-2 text-center">
      <button
        :class="
          stockBreakdown.hasPrevious != true
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
        "
        :disabled="
          stockBreakdown.hasPrevious != true || loading.search != false
        "
        @click="Navigate('prev')"
        class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
      >
        Prev
      </button>

      <span class="ml-2 mr-2 md:text-xs"
        >{{ currentPage }}/{{ numberOfPages }}</span
      >

      <button
        :class="
          stockBreakdown.hasMore != true
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
        "
        :disabled="stockBreakdown.hasMore != true || loading.search != false"
        @click="Navigate('next')"
        class="text-darkblue md:text-xs hover:text-blue-600 font-bold"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import NotificationModal from "../../components/NotificationModal.vue";
  import { mapMutations } from "vuex";
  import { tableMixin } from "@/assets/helpers/tableMixin";
  import { datePickerMixin } from "@/assets/helpers/datePickerMixin";

  export default {
    name: "SingleStock",
    props: {},
    mixins: [tableMixin, datePickerMixin],

    components: { NotificationModal },
    apollo: {
      stock: {
        query: gql`
          query stock($id: ID!) {
            stock(id: $id) {
              _id
              itemName
              unit
              unitSellingPrice
              quantityRemaining
              authorId {
                _id
                name
              }

              createdAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
      stockBreakdown: {
        query: gql`
          query stockBreakdown($id: ID!, $query: StockBreakdownInput!) {
            stockBreakdown(id: $id, query: $query) {
              result {
                itemTotal
                createdAt
                sourceId
                unitPrice
                sourceType
                quantity
                serialId
              }

              totalCount
              hasMore
              hasPrevious
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
            query: this.stockBreakdownQuery,
          };
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
        skip() {
          return this.skipBreakdownSearch;
        },
      },
    },
    data() {
      return {
        tableData: [],

        currentPage: 1,
        breakdownSearchLocation: "",
        breakdownSearchLocationOptions: [
          { value: "INVOICE", text: "Invoice" },
          { value: "STATEMENT", text: "Statement" },
        ],
        tableHeader: [
          {
            id: "",
            value: "source",
            text: "Source.",
            className: "",
          },
          {
            id: "",
            value: "sourceNo",
            text: "SourceNo.",
            className: "",
          },
          {
            value: "unitPrice",
            text: "Unit Price(₦)",
            className: "fortunate-table",
          },
          {
            value: "quantity",
            text: "Quantity(₦)",
            className: "fortunate-table",
          },
          {
            value: "itemTotal",
            text: "Item Total(₦)",
            className: "fortunate-table",
          },
          {
            value: "regDate",
            text: "Reg. Date",
            className: "fortunate-table",
          },
        ],
        skipBreakdownSearch: true,
        showDeleteModal: false,
        loadingSpinner: false,
        modalData: {
          title: "Delete Stock ",
          message:
            "Are you sure you want to delete this stock ? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },
        loading: {
          search: false,
        },

        stock: {
          itemName: "",
          unit: "",
          unitSellingPrice: "",
          quantityRemaining: "",
          authorId: {
            _id: "",
            name: "",
          },
        },
        breakdownCursor: 0,

        stockBreakdownQuery: {
          type: this.breakdownCursorType,
          cursor: 0,
          limit: 2,
          breakdownSearchLocation: "Invoice",
          dateFilter: null,
        },

        breakdownCursorType: "GT",
        breakdownLimit: 10,
        breakdownFilter: null,
        selectedDate: [],
        stockBreakdown: {
          result: [],
          hasPrevious: false,
          hasMore: false,
        },

        // paginated_table_data: [],
      };
    },
    computed: {
      numberOfPages: function () {
        return Math.ceil(
          Number(this.stockBreakdown.totalCount) / Number(this.breakdownLimit)
        );
      },
      dateFilter: function () {
        if (this.selectedDate.length > 0) {
          if (this.selectedDate.length > 1) {
            return {
              from: new Date(this.selectedDate[0]).getTime(),
              to: new Date(this.selectedDate[1]).getTime(),
            };
          }
          return {
            from: new Date(this.selectedDate[0]).getTime(),
            to: new Date(this.selectedDate[0]).getTime(),
          };
        }
        return null;
      },
      regDate: function () {
        const date = new Date(Number(this.stock.createdAt));

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),
      ClickRow(row) {
        switch (this.tableData[row.rowIndex].source) {
          case "Invoice":
            return this.$router.push({
              name: "single_invoice",
              params: { id: this.tableData[row.rowIndex].sourceId },
            });
          case "Statement":
            return this.$router.push({
              name: "singleStatement",
              params: { id: this.tableData[row.rowIndex].sourceId },
            });

          default:
            break;
        }

        // this.$emit("clicked-table", this.search.result[row.rowIndex]._id);
      },

      GetStockBreakdown() {
        if (!this.dateFilter) {
          return this.$emit("show-alert", {
            type: "error",
            message: "You need to select a date range before getting breakdown",
          });
        }
        this.skipBreakdownSearch = false;
        this.stockBreakdownQuery = {
          type: this.breakdownCursorType,
          cursor: this.breakdownCursor,
          limit: this.breakdownLimit,
          searchLocation: this.breakdownSearchLocation,
          dateFilter: this.dateFilter,
        };
      },

      SetBreakdownQuery() {
        this.stockBreakdownQuery = {
          type: this.breakdownCursorType,
          cursor: this.breakdownCursor,
          limit: this.breakdownLimit,
          searchLocation: this.breakdownSearchLocation,
          dateFilter: this.dateFilter,
        };
      },

      Navigate(type) {
        switch (type) {
          case "prev":
            this.breakdownCursorType = "LT";
            this.currentPage--;

            this.breakdownCursor = this.stockBreakdown.result[0].serialId;
            this.SetBreakdownQuery();
            break;

          case "next":
            this.breakdownCursorType = "GT";
            this.currentPage++;
            this.breakdownCursor =
              this.stockBreakdown.result[
                this.stockBreakdown.result.length - 1
              ].serialId;
            this.SetBreakdownQuery();

            break;

          default:
            break;
        }
      },

      async DeleteStock() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation ($id: ID!) {
                deleteStock(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteStock._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Stock  deleted successfully`,
            });

            return this.$router.push("/app/stock");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting stock ,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting stock ,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },

      TableButton() {},
      EditStock() {
        this.$router.push({
          name: "manageStock",
          params: { mode: "edit", id: this.$route.params.id },
        });
      },
      FormatTableData(data) {
        this.table_data = data.map((invoice) => {
          return {
            _id: invoice._id,
            invoice_no: invoice.serialId,
            amount: invoice.invoiceTotal,
            customer_name: invoice.customerId.customerName,
            status: invoice.completedPayment === true ? "paid" : "pending",
            btn: "btn",
          };
        });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },

      ChangeCustomerMode(mode) {
        this.mode = mode;
      },
    },
    watch: {
      getCustomersInvoice(newValue) {
        this.FormatTableData(newValue.result);
      },
      breakdownLimit() {
        this.breakdownCursor = 0;
        this.breakdownCursorType = "GT";
        this.currentPage = 1;
      },
      stockBreakdown: {
        deep: true,
        handler(newValue) {
          let date = new Date(Number(this.stock.createdAt));

          date = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`;

          this.tableData = newValue.result.map((breakdown) => {
            return {
              source: breakdown.sourceType,
              unitPrice: breakdown.unitPrice.toLocaleString(),
              quantity: breakdown.quantity,
              itemTotal: breakdown.itemTotal.toLocaleString(),
              sourceId: breakdown.sourceId,
              sourceNo: breakdown.serialId,
              regDate: date,
            };
          });
        },
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Stock", pathName: "stock" },

        {
          displayName: "Single Stock",
          pathName: "singleStock",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }
</style>
